
import { getPHPAPIFormattedDate } from "@/helpers/dateProcessor";
import Vue from "vue";
export default Vue.extend({
  name: "ViewChangeRequestPolicyDetails",
  props: {
    changeRequestData: {
      type: Object,
      required: true
    }
  },
  methods: {
    makeHumanReadableDate(date: string) {
      return getPHPAPIFormattedDate(new Date(date));
    }
  }
});
